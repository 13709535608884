import React, { Component } from 'react';
import AppContext from '../context/AppContext';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
export default class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.closeModalByEsc = this.closeModalByEsc.bind(this);
  }

  closeModalByEsc(event) {
    if (event.key === 'Escape') {
      this.props.app.toggleImageModal();
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.closeModalByEsc, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeModalByEsc, false);
  }

  render() {
    let app = this.props.app;

    return (
      <div
        className="img-modal"
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 20
        }}
      >
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0, 0.7)'
          }}
        >
          <div className="modal-container">
            <div className="container">
              <div
                className={`img-wrapper ${app.currentStepImageMobile ? 'current-step-image-mobile' : ''}`}
                style={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <TransformWrapper initialScale={1}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <div className="tools">
                        <div className="zoom-tools">
                          <button onClick={() => zoomIn()} className="zoom-image-modal">
                            +
                          </button>
                          <button onClick={() => zoomOut()} className="shrink-image-modal">
                            -
                          </button>
                        </div>
                        <div className="close-tool">
                          <button onClick={() => app.toggleImageModal()} className="close-image-modal">
                            x
                          </button>
                        </div>
                      </div>
                      <TransformComponent>
                        <img
                          className="img-fluid"
                          src={app.currentStepImageSrc}
                          alt={app.currentStepImageAlt}
                          style={{ width: '100%', height: '100%' }}
                          onLoad={() => zoomOut()}
                        />
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
