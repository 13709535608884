/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import '../_scss/App.scss';
import Footer from './Footer';
import Privacy from './Privacy';
import Languages from './Languages';
import SkylineFooter from './SkylineFooter';
import AppContext from '../context/AppContext';
import PencilBanner from './PencilBanner';
import ImageModal from './ImageModal';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll = () => {
    this.setState({ scroll: window.scrollY });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    let isMobileDevice = this.checkIfMobileDevice();
    this.setState({
      isMobileDevice: isMobileDevice
    });
    // make sure that the window is in focus so keyboard events will work on tour page load
    let tourPage = document.querySelector('.page-tour');
    if (tourPage !== null) {
      tourPage.focus();
    }
  }

  componentWillUpdate() {
    let headerWrapper = document.querySelector('.header-wrapper');
    let previousPosition = window.scrollY;
    let isLandScape = window.orientation === 90;
    let isPortrait = window.orientation === 0;
    let isTablet = window.matchMedia('(max-width: 768px) and (min-width: 650px)').matches || isLandScape;

    window.addEventListener('scroll', function() {
      let atBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      if (!isTablet) {
        if (window.scrollY > previousPosition) {
          if (!headerWrapper.classList.contains('fade')) {
            headerWrapper.classList.add('fade');
          }
          if (headerWrapper.classList.contains('scroll-up')) {
            headerWrapper.classList.remove('scroll-up');
          }
        } else {
          if (headerWrapper.classList.contains('fade')) {
            headerWrapper.classList.remove('fade');
          }
          if (!headerWrapper.classList.contains('scroll-up')) {
            headerWrapper.classList.add('scroll-up');
          }
        }
        if (window.scrollY === 0) {
          if (!headerWrapper.classList.contains('at-top')) {
            headerWrapper.classList.add('at-top');
          }
          if (headerWrapper.classList.contains('fade')) {
            headerWrapper.classList.remove('fade');
          }
          if (headerWrapper.classList.contains('scroll-up')) {
            headerWrapper.classList.remove('scroll-up');
          }
        } else {
          if (headerWrapper.classList.contains('at-top')) {
            headerWrapper.classList.remove('at-top');
          }
        }
      } else {
        if (isPortrait) {
          if (window.scrollY > previousPosition) {
            headerWrapper.classList.add('fade');
          } else {
            headerWrapper.classList.remove('fade');
            headerWrapper.classList.add('scroll-up');
            if (atBottom) {
              headerWrapper.classList.remove('scroll-up');
              headerWrapper.classList.add('fade');
            }
          }
          if (window.scrollY === 0) {
            if (!headerWrapper.classList.contains('at-top')) {
              headerWrapper.classList.add('at-top');
            }
            if (headerWrapper.classList.contains('fade')) {
              headerWrapper.classList.remove('fade');
            }
            if (headerWrapper.classList.contains('scroll-up')) {
              headerWrapper.classList.remove('scroll-up');
            }
          } else {
            headerWrapper.classList.remove('at-top');
          }
        }
        if (isLandScape) {
          if (window.scrollY > previousPosition) {
            if (!headerWrapper.classList.contains('fade')) {
              headerWrapper.classList.add('fade');
            }
            if (!headerWrapper.classList.contains('scroll-up')) {
              headerWrapper.classList.add('scroll-up');
            }
          } else {
            if (!headerWrapper.classList.contains('scroll-up')) {
              headerWrapper.classList.add('scroll-up');
            }
            if (headerWrapper.classList.contains('fade')) {
              headerWrapper.classList.remove('fade');
            }
            if (atBottom) {
              if (headerWrapper.classList.contains('scroll-up')) {
                headerWrapper.classList.remove('scroll-up');
              }
              if (!headerWrapper.classList.contains('fade')) {
                headerWrapper.classList.add('fade');
              }
            }
          }
          if (window.scrollY === 0 || window.scrollY < 0) {
            if (!headerWrapper.classList.contains('at-top')) {
              headerWrapper.classList.add('at-top');
            }
            if (headerWrapper.classList.contains('fade')) {
              headerWrapper.classList.remove('fade');
            }
            if (headerWrapper.classList.contains('scroll-up')) {
              headerWrapper.classList.remove('scroll-up');
            }
          } else {
            if (headerWrapper.classList.contains('at-top')) {
              headerWrapper.classList.remove('at-top');
            }
          }
        }
      }
      previousPosition = window.scrollY;
    });
  }

  checkIfMobileDevice = () => {
    return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
  };

  render() {
    const { children, location, lang, languages } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            prismic {
              allGlobal_navigations {
                edges {
                  node {
                    pencil_banner {
                      pencil_banner_text
                      pencil_banner_link_text
                      custom_page {
                        ... on PRISMIC_Gov_tours_custom_page {
                          _meta {
                            uid
                            lang
                            id
                            type
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <AppContext.Consumer>
            {app => {
              // flter out navs that don't have pencil banner
              let pencilBanner = data.prismic.allGlobal_navigations.edges.filter(x => x.node.pencil_banner !== null);

              // Map and filter by language
              pencilBanner = pencilBanner.map(c =>
                c.node.pencil_banner.filter(k => k.custom_page !== null && k.custom_page._meta.lang === this.props.lang)
              );

              // filter out any empty arrays
              pencilBanner =
                pencilBanner.filter(j => j.length && j.length > 0).length > 0
                  ? pencilBanner.filter(j => j.length && j.length > 0)[0]
                  : null;

              return (
                <div
                  className={`page-${location} ${this.state.isMobileDevice ? 'mobile' : 'desktop'}`}
                  onKeyDown={e => app.handleKeyPress(e, location)}
                  tabIndex="0"
                  onClick={() => app.closeOpenDialogs(location)}
                >
                  <div className={`header-wrapper ${!app.hideHeader ? '' : 'hidden'}`}>
                    <Languages
                      mobile={false}
                      lang={lang}
                      languages={languages}
                      updateLanguage={app.updateLanguage}
                      visible={app.languageVisible}
                      toggleVisible={app.toggleLanguageVisible}
                    />
                    {pencilBanner !== null && (
                      <PencilBanner mobile={this.state.isMobileDevice} lang={lang} bannerData={pencilBanner[0]} />
                    )}
                    <Header siteTitle={data.site.siteMetadata.title} lang={lang} location={location} />
                  </div>
                  <div className="content-area">
                    {app.showImageModal && <ImageModal app={app} />}
                    <main>{children}</main>
                  </div>
                  <SkylineFooter />
                  <Languages
                    mobile={true}
                    lang={lang}
                    languages={languages}
                    updateLanguage={app.updateLanguage}
                    visible={app.languageVisible}
                    toggleVisible={app.toggleLanguageVisible}
                  />
                  <Footer lang={lang} />
                  <Privacy />
                </div>
              );
            }}
          </AppContext.Consumer>
        )}
      />
    );
  }
}

export default Layout;
