import React, { Component } from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import { linkResolver } from '../utils/linkResolver';

class PencilBanner extends Component {
        
    render() {
        
        const { lang, bannerData, mobile } = this.props
            return (
                <div className="pencil-banner-container">
                    <div className="container">
                    {!mobile ? (
                        <div className="pencil-banner">
                            <span>{RichText.asText(bannerData.pencil_banner_text)} | </span><Link to={linkResolver(bannerData.custom_page._meta)}>{RichText.asText(bannerData.pencil_banner_link_text)}</Link>
                        </div>
                    ) : (
                        <div className="pencil-banner mobile">
                            {RichText.asText(bannerData.pencil_banner_text)} <br />
                            <Link to={linkResolver(bannerData.custom_page._meta)}>{RichText.asText(bannerData.pencil_banner_link_text)}</Link>
                        </div>
                    )}
                    </div>
                </div>
            );
    }
}

export default PencilBanner;