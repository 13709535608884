import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Button from './Button';
import AppContext from '../context/AppContext';
import { linkResolver } from '../utils/linkResolver';

class Header extends Component {
  render() {
    let { lang } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query HeaderQuery {
            prismic {
              allGlobal_navigations {
                edges {
                  node {
                    ...GlobalNavigationFragment
                    phone_number
                  }
                }
              }
            }
          }
        `}
        render={data => {
          let navigation = data.prismic.allGlobal_navigations.edges.filter(e => e.node._meta.lang === lang)[0].node;
          let ctaText = navigation.global_cta[0].global_cta_label[0].text;
          return (
            <div className="top-navbar">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xs-12 col-md-12 col-lg-12">
                    <div className="top-navbar-wrapper">
                      <div className="branding-container">
                        <AppContext.Consumer>
                          {app => (
                            <div className="logo-title-container">
                              <Link
                                to={linkResolver({ type: 'homepage', lang: lang })}
                                onClick={() => app.clearPlaylistFromTour(this.props.location)}
                              >
                                <img className="logo" alt="Salesforce" src={navigation.logo.url} />
                              </Link>
                              <span className="title">{RichText.asText(navigation.title)}</span>
                            </div>
                          )}
                        </AppContext.Consumer>
                      </div>
                      <div className="cta-container">
                        <a href={`tel:${RichText.asText(navigation.phone_number)}`} className="cta-number">
                          {RichText.asText(navigation.phone_number)}
                        </a>
                        <Button
                          type="button"
                          id="cta-main"
                          mobile={false}
                          text={`${ctaText}`}
                          link={`${
                            navigation.global_cta[0].global_cta_url.url
                          }`}
                          icon="null"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default Header;
