import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

class Footer extends Component {
  render() {
    let { className, lang } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            prismic {
              allGlobal_navigations {
                edges {
                  node {
                    ...GlobalNavigationFragment
                  }
                }
              }
            }
          }
        `}
        render={data => {
          let footerData;
          let currentYear = new Date().getFullYear();
          footerData = data.prismic.allGlobal_navigations.edges.filter(x => x.node._meta.lang === lang)[0].node;
          let allRightsReserved =
            lang === 'en-us'
              ? footerData.legal_message[0].text.split('.')[0]
              : footerData.legal_message[0].text.split('©')[0];
          let legalMessage =
            lang === 'en-us'
              ? footerData.legal_message[0].text.replace(allRightsReserved + '.', '')
              : footerData.legal_message[0].text.replace(allRightsReserved, '');

          return (
            <footer id="footer" className={`footer ${className === undefined ? '' : className}`}>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    <p>{RichText.asText(footerData.disclaimer_message)}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    <ul className="footer-links">
                      <li className="footer-link">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.salesforce.com/company/legal/sfdc-website-terms-of-service.jsp"
                          target="_blank"
                        >
                          Terms and Service
                        </a>
                      </li>
                      <li className="footer-link">
                        <a rel="noopener noreferrer" href="https://www.salesforce.com/company/privacy/" target="_blank">
                          Privacy
                        </a>
                      </li>
                      <li className="footer-link">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.salesforce.com/company/disclosure/"
                          target="_blank"
                        >
                          Responsible Disclosure
                        </a>
                      </li>
                      <li className="footer-link">
                        <a rel="noopener noreferrer" href="https://trust.salesforce.com/" target="_blank">
                          Trust
                        </a>
                      </li>
                      <li className="footer-link">
                        <a className="optanon-show-settings">Cookie Preferences</a>
                      </li>
                      <li className="footer-link">
                        <a
                          href="https://www.salesforce.com/form/other/privacy-request/?d=cta-footer-1"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            src="/images/icon-cpra.svg"
                            style={{ height: '15px', width: '30px', marginRight: '5px' }}
                          />
                          Your Privacy Choices
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col footer-copy">
                    <span>
                      {lang === 'en-us' && `© Copyright ${currentYear} Salesforce.com, inc. `}
                      <a
                        rel="noopener noreferrer"
                        href="http://www.salesforce.com/company/legal/intellectual.jsp"
                        target="_blank"
                      >
                        {allRightsReserved}.
                      </a>
                      <span className="mobile-address">{legalMessage}</span>
                    </span>
                    <span className="desktop-address">{legalMessage}</span>
                    <div className="mobile-learn-more-cta-wrapper">
                      <div className="mobile-learn-more-cta">
                        {lang === 'en-us' && `To learn more call `}
                        <a href={`tel:${footerData.phone_number[0].text}`} className="cta-number">
                          {footerData.phone_number[0].text}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          );
        }}
      />
    );
  }
}

export default Footer;
