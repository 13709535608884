import React, { Component } from 'react';

class Languages extends Component {

  renderLanguages() {
    const { updateLanguage, languages, lang } = this.props;

    let currentLanguages = languages.filter(l => l._meta.lang === lang);

    return currentLanguages.map((language, index) => {
      return (
        <li className="language-item" key={index} onClick={() => updateLanguage(language)}>
          <span className="flag">
            <img src={language.flag_icon.url} alt={language.label} />
          </span>
          <span className="language-check-flex">
            <span>{`${language.label}`} </span>
            <span className={`language-check ${language.lang === lang ? '' : 'hidden'}`}>&#x2713;</span>
          </span>
        </li>
      );
    });
  }

  render() {
    const { mobile, languages, lang, visible, toggleVisible } = this.props;

    let selectedLanguage = languages.filter(l => l._meta.lang === lang).filter(sl => sl.lang === lang)[0];

    if (!selectedLanguage) {
      selectedLanguage = {
        label: 'English'
      };
    }

    if (mobile) {
      return (
        <div className="languages-wrapper mobile">
          <div className="container">
            <ul className="initial-ul mobile">
              <li onClick={() => toggleVisible()} className="default-language">
                {`${selectedLanguage.label}`}
                <span className={`nav-icon ${visible ? 'nav-open' : ''}`}>
                  <svg width="8px" height="5px" viewBox="0 0 8 5" version="1.1">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="D---Homepage" transform="translate(-1202.000000, -8.000000)" fill="#FFFFFF">
                        <g id="Header">
                          <g id="Language">
                            <g id="Group-3" transform="translate(1202.000000, 8.000000)">
                              <path
                                d="M3.7344,4.5437 L0.109733333,0.863033333 C-0.0369333333,0.716366667 -0.0369333333,0.479033333 0.109733333,0.332366667 L0.331733333,0.110366667 C0.479066667,-0.0369666667 0.7164,-0.0369666667 0.863066667,0.110366667 L3.99973333,3.30636667 L7.13706667,0.113033333 C7.28373333,-0.0336333333 7.52173333,-0.0336333333 7.66773333,0.113033333 L7.88973333,0.335033333 C8.0364,0.4817 8.0364,0.7197 7.88973333,0.866366667 L4.26573333,4.54703333 C4.11906667,4.69036667 3.88106667,4.69036667 3.7344,4.5437"
                                id="Fill-1"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <ul className={`sub-nav mobile ${!visible ? 'hide' : ''}`}>{this.renderLanguages()}</ul>
              </li>
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="languages-wrapper">
          <div className="container">
            <ul className="initial-ul">
              <li onClick={() => toggleVisible()} className="default-language">
                <span className="language-button">
                {`${selectedLanguage.label}`}
                <span className={`nav-icon ${visible ? 'nav-open' : ''}`}>
                  <svg width="8px" height="5px" viewBox="0 0 8 5" version="1.1">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="D---Homepage" transform="translate(-1202.000000, -8.000000)" fill="#FFFFFF">
                        <g id="Header">
                          <g id="Language">
                            <g id="Group-3" transform="translate(1202.000000, 8.000000)">
                              <path
                                d="M3.7344,4.5437 L0.109733333,0.863033333 C-0.0369333333,0.716366667 -0.0369333333,0.479033333 0.109733333,0.332366667 L0.331733333,0.110366667 C0.479066667,-0.0369666667 0.7164,-0.0369666667 0.863066667,0.110366667 L3.99973333,3.30636667 L7.13706667,0.113033333 C7.28373333,-0.0336333333 7.52173333,-0.0336333333 7.66773333,0.113033333 L7.88973333,0.335033333 C8.0364,0.4817 8.0364,0.7197 7.88973333,0.866366667 L4.26573333,4.54703333 C4.11906667,4.69036667 3.88106667,4.69036667 3.7344,4.5437"
                                id="Fill-1"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                </span>
                <ul className={`sub-nav ${!visible ? 'hide' : ''}`}>{this.renderLanguages()}</ul>
              </li>
            </ul>
          </div>
          {visible ? (<div className="languages-blocker" onClick={() => toggleVisible()}></div>) : (undefined)}
        </div>
      );
    }
  }
}
export default Languages;
