import React, { Component } from 'react';
// import TourFooterAllFrance from '../components/TourFooterAllFrance';
// import TourFooterAll from '../components/TourFooterAll';
import TourFooterCloudsMobile from '../components/TourFooterCloudsMobile';
import SkylineFooterFrance from '../_images/global/sf-skyline-all-2020.svg';
import MoutainFooter from '../_images/global/tour-footer.png';

class SkylineFooter extends Component {
  render() {
    return (
      <div className="skyline-footer">
        <div className="skyline-all">
          <img src={MoutainFooter} alt="skyline footer" />
        </div>
      </div>
    );
  }
}

export default SkylineFooter;
